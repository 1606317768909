/**
 * MUI Buttons
 */

@mixin x-btn-box-shadow-raised() {
  box-shadow: 0 0px 2px rgba(mui-color('black'), 0.12),
              0 2px 2px rgba(mui-color('black'), 0.20);
  
  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    box-shadow: 0 -1px 2px rgba(mui-color('black'), 0.12),
                -1px 0px 2px rgba(mui-color('black'), 0.12),
                0 0px 2px rgba(mui-color('black'), 0.12),
                0 2px 2px rgba(mui-color('black'), 0.20);
  }
}

@mixin x-btn-box-shadow-active() {
  box-shadow: 0 10px 20px rgba(mui-color('black'), 0.19),
              0 6px 6px rgba(mui-color('black'), 0.23);

  // IE10+
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    box-shadow: 0 -1px 2px rgba(mui-color('black'), 0.12),
                -1px 0px 2px rgba(mui-color('black'), 0.12),
                0 10px 20px rgba(mui-color('black'), 0.19),
                0 6px 6px rgba(mui-color('black'), 0.23);
  }
}

// ============================================================================
// DEFAULT-BUTTON
// ============================================================================

.mui-btn {
  @include mui-node-inserted();
  @include mui-text("button");

  @include button-variant(
    $mui-btn-default-font-color,
    $mui-btn-default-bg-color);

  @include button-flat-variant(
    $mui-btn-default-font-color,
    $mui-btn-flat-bg-color-hover);

  transition: all 0.2s ease-in-out;
  
  // Layout
  display: inline-block;
  height: $mui-btn-height;
  padding: 0 $mui-btn-padding-horizontal;
  margin-top: $mui-btn-margin-vertical;
  margin-bottom: $mui-btn-margin-vertical;
  
  // Look and feel
  border: none;
  border-radius: $mui-btn-border-radius;
  cursor: pointer;
  touch-action: manipulation;
  background-image: none;  // reset unusual Firefox-on-Android

  // Typography
  text-align: center;
  line-height: $mui-btn-height;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  font-size: $mui-btn-font-size;
  letter-spacing: 0.03em;

  // For ripples
  position: relative;
  overflow: hidden;
  
  &:hover,
  &:focus,
  &:active {
    outline: 0;
    text-decoration: none;
    color: $mui-btn-default-font-color;
  }

  &:hover,
  &:focus {
    @include x-btn-box-shadow-raised();

  }
  
  &:active {
    @include x-btn-box-shadow-active();
  }
  
  &:disabled,
  &.mui--is-disabled {
    cursor: $mui-cursor-disabled;
    pointer-events: none;
    opacity: 0.60;
    box-shadow: none;
  }
}

.mui-btn + .mui-btn {
  margin-left: $mui-btn-spacing-horizontal;
}



// ============================================================================
// BUTTON-TYPES
// ============================================================================


// ----------------------------------------------------------------------------
// FLAT
// ----------------------------------------------------------------------------

.mui-btn--flat {
  background-color: $mui-btn-flat-bg-color;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none;
    background-color: $mui-btn-flat-bg-color-hover;
  }
}


// ----------------------------------------------------------------------------
// RAISED
// ----------------------------------------------------------------------------

.mui-btn--raised {
  @include x-btn-box-shadow-raised();

  &:active {
    @include x-btn-box-shadow-active();
  }
}


// ----------------------------------------------------------------------------
// FLOATING-ACTION-BUTTON-(FAB)
// ----------------------------------------------------------------------------

.mui-btn--fab {
  @extend .mui-btn--raised;

  // Layout
  position: relative;
  padding: 0;
  width: $mui-btn-fab-diameter;
  height: $mui-btn-fab-diameter;
  line-height: $mui-btn-fab-diameter;
  border-radius: 50%;
  z-index: 1;
}



// ============================================================================
// BUTTON-STYLES
// ============================================================================

.mui-btn--primary {
  @include button-variant(
    $mui-btn-primary-font-color,
    $mui-btn-primary-bg-color);
  @include button-flat-variant(
    $mui-btn-primary-bg-color,
    $mui-btn-flat-bg-color-hover);
}

.mui-btn--dark {
  @include button-variant(
    $mui-btn-dark-font-color,
    $mui-btn-dark-bg-color);
  @include button-flat-variant(
    $mui-btn-dark-bg-color,
    $mui-btn-flat-bg-color-hover);
}

.mui-btn--danger {
  @include button-variant(
    $mui-btn-danger-font-color,
    $mui-btn-danger-bg-color);
  @include button-flat-variant(
    $mui-btn-danger-bg-color,
    $mui-btn-flat-bg-color-hover);
}

.mui-btn--accent {
  @include button-variant(
    $mui-btn-accent-font-color,
    $mui-btn-accent-bg-color);
  @include button-flat-variant(
    $mui-btn-accent-bg-color,
    $mui-btn-flat-bg-color-hover);
}



// ============================================================================
// BUTTON-SIZES
// ============================================================================

.mui-btn--small {
  height: $mui-btn-height-sm;
  line-height: $mui-btn-height-sm;
  padding: 0 $mui-btn-padding-horizontal-sm;
  font-size: $mui-btn-font-size-sm;
}

.mui-btn--large {
  height: $mui-btn-height-lg;
  line-height: $mui-btn-height-lg;
  padding: 0 $mui-btn-padding-horizontal-lg;
  font-size: $mui-btn-font-size-lg;
}

.mui-btn--fab.mui-btn--small {
  width: $mui-btn-fab-diameter-sm;
  height: $mui-btn-fab-diameter-sm;
  line-height: $mui-btn-fab-diameter-sm;
}

.mui-btn--fab.mui-btn--large {
  width: $mui-btn-fab-diameter-lg;
  height: $mui-btn-fab-diameter-lg;
  line-height: $mui-btn-fab-diameter-lg;
}
