/**
 * MUI Panel module
 */

.mui-panel {
  @include mui-clearfix();

  padding: $mui-panel-padding;
  margin-bottom: $mui-base-line-height-computed;
  border-radius: $mui-panel-border-radius;
  background-color: $mui-panel-bg-color;
  box-shadow: 0 2px 2px 0 rgba(mui-color('black'), 0.16),
              0 0px 2px 0 rgba(mui-color('black'), 0.12);

  // IE10+ bugfix
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    box-shadow: 0 -1px 2px 0 rgba(mui-color('black'), 0.12),
                -1px 0px 2px 0 rgba(mui-color('black'), 0.12),
                0 2px 2px 0 rgba(mui-color('black'), 0.16),
                0 0px 2px 0 rgba(mui-color('black'), 0.12);
  }
}
