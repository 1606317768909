/**
 * MUI Reboot
 */

// ============================================================================
// GLOBAL
// ============================================================================

// Reset box sizing
* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
}

// Body reset
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: $mui-base-font-family;
  font-size: $mui-base-font-size;
  font-weight: $mui-base-font-weight;
  line-height: $mui-base-line-height;
  color: $mui-base-font-color;
  background-color: $mui-body-bg-color;
}

@if $mui-base-font-smoothing == true {
  html,
  body,
  button,  // safari issue
  input,  // safari issue
  textarea {  // safari issue
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }
}




// ============================================================================
// TAG STYLES
// ============================================================================

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links
a {
  color: $mui-link-font-color;
  text-decoration: $mui-link-text-decoration;

  &:hover,
  &:focus {
    color: $mui-link-font-color-hover;
    text-decoration: $mui-link-text-decoration-hover;
  }

  &:focus {
    @include mui-tab-focus();
  }
}

// paragraphs
p {
  margin: 0 0 ($mui-base-line-height-computed / 2);
}

// lists
ul,
ol {
  margin-top: 0;
  margin-bottom: ($mui-base-line-height-computed / 2);
}

// Figures
// We reset this here because previously Normalize had no `figure` margins. 
// This ensures we dont break anyones use of the element.
figure {
  margin: 0;
}

// Images
img {
  vertical-align: middle;
}

// Horizontal rules
hr {
  margin-top: $mui-base-line-height-computed;
  margin-bottom: $mui-base-line-height-computed;
  border: 0;
  height: 1px;
  background-color: $mui-hr-color;
}

// Form legend
legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: $mui-base-line-height-computed / 2;
  font-size: $mui-legend-font-size;
  color: $mui-legend-font-color;
  line-height: inherit;
  border: 0;
}

// override content-box in normalize.css
input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: none;  // Search inputs in iOS
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  // Focus for file, radio, and checkbox
  @include mui-tab-focus();
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  cursor: $mui-cursor-disabled;
}

// Strong
strong {
  font-weight: 700;
}

// Abbreviations
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted $mui-abbr-border-color;
}

// headers
h1 {
  @extend .mui--text-display1;
}

h2 {
  @extend .mui--text-headline;
}

h3 {
  @extend .mui--text-title;
}

h4 {
  @extend .mui--text-subhead;
}

h5 {
  @extend .mui--text-body2;
}

h1, h2, h3 {
  margin-top: $mui-base-line-height-computed;
  margin-bottom: ($mui-base-line-height-computed / 2);
}

h4, h5, h6 {
  margin-top: ($mui-base-line-height-computed / 2);
  margin-bottom: ($mui-base-line-height-computed / 2);
}
