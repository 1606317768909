@import "bower_components/mui/src/sass/mui/colors";
$mui-accent-color:        mui-color('orange', 'A200');
$mui-accent-color-dark:   mui-color('orange', 'A400');
$mui-accent-color-light:  mui-color('orange', 'A100');

// import MUI SASS
@import "bower_components/mui/src/sass/mui";
body, html{
	width:100%;
	height:100%;
	padding:0;
	margin:0;
}

#map{
    height: calc(100% - 67px);
}
#admin-map{
    height: 100%;
}

.me-utils--margin-bottm-0{
	margin-bottom: 0 !important;
}

.me-utils--50px-wide{
	width: 50px;
	flex-grow: 1;
}

.me-utils--align-self-stretch{
	align-self: stretch;
}
.me-utils--align-items-center{
	align-items: center;
}

.me-utils--left-0{
	left:0;
}

.me-utils---padding-left-100{
	padding-left: 100px;
}

.me-utils--absolute{
	position: absolute;
}

.me-utils--visibility{
	color: rgb(33, 150, 243);
}
.me-utils--visibility-off{
	color: rgb(244, 67, 54);
}

.me-utils--nopadding{
	padding: 0 !important;
}

.me-utils--dropdown{
	.mui-dropdown__menu{
		height: calc(44px * 6 + 20px) !important;
		column-count:3;
		column-gap:0;

		overflow-y: auto;

		li{
			a{
				padding:0 !important;
			}
			.mui-checkbox{
				margin:0 !important;
				label{
					display: block;
					padding: 10px 20px;
				}
			}
			.mui-checkbox input{
				display: none;
			}
		}
	}
}

.me-utils--reduced-padding{
	padding:0 4px !important;
}

.me-utils--full-height{
	height: 100% !important;
}

.me-utils--full-width{
	width: 100% !important;
}

.me-utils--flex{
	display: flex !important;
}

.me-utils--items-center{
	align-items: center;
}

.me-utils--flex-grow-1{
	flex-grow: 1 !important;
}

.me-utils--fab{
	position: absolute;
	right:24px;
	bottom:24px;
}

.me-utils--justify-content-space-between{
	justify-content: space-between;
}

.me-utils--3em-wide{
	width: 3em !important;
}

.me-utils--no-margin-bottom{
	margin-bottom:0 !important;
}

.pac-item, .pac-item-query{
	font-size:20px;
}

.pac-item{
	padding: 10px;
}

.pac-container{
	width: 100% !important;;
	left: 0 !important;
}

.admin-tab-bar{
	height: 50px;
	overflow: hidden;
}

.admin-tab{
	height: calc(100% - 50px - 67px) !important;
}

.admin-content{
	height: calc(50% - 1px);
	max-height: 95%;
	resize: vertical;
	overflow: auto;
	margin:0 !important;
}

.input-header{
	// table-layout: fixed;
	td:not(.select-td){
		overflow: hidden;
		white-space:nowrap;
		text-overflow: ellipsis;
	}
}

.hash{
	max-width: 5em;
	overflow: hidden;
	white-space:nowrap;
	text-overflow: ellipsis;
}

.no-wrap{
	white-space: nowrap;
}

.me-utils-blue{
	color: #4CAF50 !important;
}

.me-utils-btn-nomargin{
	margin: 0 !important;
}

.thumb{
	width: 16px;
}

.active-tr{
	background: #E1F5FE !important;
}


.me-utils--fixed-header {
  border: 0;
  border-collapse: collapse;
  width:100%;
  height: 100%;
  margin-bottom: 0 !important;

  .mui--z1, .mui-dropdown__menu, .mui-select__menu{
		top: 0 !important;
    height: calc(42px * 3) !important;
	}

	tr {
	  display: flex;
	}

	td {
	  padding: 3px;
	  flex: 1 auto;
	  // border: .1px solid #fee;
	  width: 3em;
	  word-wrap: break-word;
	  display: flex;
	  align-items:center;
	  justify-content: center;

		.mui-textfield, .mui-select{
			padding-top: 0 !important;
			margin-bottom:0 !important;
		}
	}

	thead tr:after {
	  content: '';
	  overflow-y: scroll;
	  visibility: hidden;
	  height: 0;
	}

	thead th {
	  flex: 1 auto;
	  width: 1px;
	  display: block;
	  // border: 1px solid #000;
	}

	tbody {
	  display: block;
	  width: 100%;
	  overflow-y: auto;
	  height: 100%;
	}

	.me-id, .me-hash, .me-user, .me-lat, .me-lng, .me-adresa, .me-apt, .me-inaltime, .me-spatii, .me-interfon, .me-stadiu,
	.me-respins, .me-editor, .me-created, .me-poze{
		span{
			overflow: hidden;
			white-space:nowrap;
			text-overflow: ellipsis;
		}
	}

	.me-user, .me-editor, .me-tip{
		flex-grow: 20 !important
	}

	.me-lat, .me-lng, .me-observatii,{
		flex-grow: 30 !important;
	}

	.me-adresa, .me-created{
		flex-grow: 40 !important;
	}

	.me-id, .me-hash, .me-apt, .me-inaltime, .me-spatii, .me-interfon, .me-stadiu, .me-respins, .me-poze, .me-actiuni{
		flex-grow: 1 !important;
		width: 4em !important;
	}

	.me-apt{
		width:4.5em !important;
	}

	.me-inaltime, .me-stadiu{
		width: 7em !important;
		overflow: visible !important;
	}

	.me-actiuni{
		min-width: 150px;
	}
}


.me-utils--fixed-header-2{
		box-sizing: border-box;
	  display: flex;
	  flex-direction: column;
	  justify-content: flex-start;
	  width: 100%;
	  height: calc(100% - 20px);
	  overflow: hidden;
	  position: relative;

	* {
	  box-sizing: border-box;
	}

	thead, tbody{
	  width: 100%;
	  display: flex;
	  flex-direction:column;
	  justify-content: flex-start;
	  /* border: thin solid blue; */
	  /* margin: 2px; */
	}

	thead{
	  min-height: min-content;
	  flex-shrink: 0;
	  /* flex-grow: 2; */
	}

	tbody{
	  flex-shrink: 1;
	  /* flex-grow: 1; */
	  /* height: 80%; */
	  overflow-y: scroll;
	  -webkit-overflow-scrolling: touch;
	  -ms-overflow-style: none;
	  backface-visibility: hidden;
	  will-change: overflow;

	  &::-webkit-scrollbar{
		  background: transparent;
		  width: .5em;
		  // height: .5em;
		}

		&::-webkit-scrollbar-thumb {
		  background: #90CAF9;
		  border-radius: 10px;
		}
		&::-webkit-scrollbar-track{
		  border: thin solid #90CAF9;
		  border-radius: 10px;
		  // margin-bottom: 1em;
		}
	}

	tbody::-webkit-scrollbar{
	  // display:none;
	}

	thead tr{
	  // background: #ddd;
	}

	thead tr:after {
	  content: '';
	  overflow-y: scroll;
	  visibility: hidden;
	  width: .5em;
	  height: 0;
	}

	tbody tr:nth-child(odd){
	  // background: #fff;
	}
	tbody tr:nth-child(even){
	  background: #FFF8E1;
	}

	tr{
	  width:100%;
	  flex-shrink: 0;
	  /* border: thin solid green; */
	  display: flex;
	  flex-wrap: wrap;
	  padding: 2px;
	  justify-content: flex-end;
	  /* align-items: flex-end; */
	  /* align-content: flex-end; */
	}

	td, th{
	  /* border: thin solid orange; */
	  width: calc( (100% - 200px) / 32);
	  justify-content: center;
	  align-items: center;
	  display:flex;
	  padding: 2px;
	  min-height: min-content;
	  min-width: 2em;
	  flex-shrink:1;

		.mui--z1, .mui-dropdown__menu, .mui-select__menu{
			top: 0 !important;
	    height: calc(42px * 3) !important;
		}
		.mui-textfield, .mui-select{
			padding-top: 0 !important;
			margin-bottom:0 !important;
			width: 100%;
		}

	}

	th{
		.mui-textfield{
			padding-top: 15px !important;
		}
	}

	td:nth-child(1), th:nth-child(1){ /* id */
	  // max-width: 5em;
	  flex-grow:1;
	}
	td:nth-child(2), th:nth-child(2){ /* hash */
	  /* max-width: 5%; */
	  flex-grow:1;
	}
	td:nth-child(3), th:nth-child(3){ /* user */
	  // max-width: 5em;
	  flex-grow:10;
	}
	td:nth-child(4), th:nth-child(4){ /* lat */
	  /* max-width: 5%; */
	  flex-grow:20;
	}
	td:nth-child(5), th:nth-child(5){ /* lng */
	  /* max-width: 5%; */
	  flex-grow:20;
	}
	td:nth-child(6), th:nth-child(6){ /* adresa */
	  /* width: calc(100% / 18 * 3); */
	  flex-grow:100;
	}
	td:nth-child(7), th:nth-child(7){ /* apt */
	  // max-width: 5em;
	  flex-grow:5;
	}
	td:nth-child(8), th:nth-child(8){ /* inaltime */
	  // max-width: 7em;
	  flex-grow:10;
	}
	td:nth-child(9), th:nth-child(9){  /* spatii */
	  // max-width: 4em;
	  flex-grow:1;
	}
	td:nth-child(10), th:nth-child(10){  /* interfon */
	  // max-width: 4em;
	  flex-grow:1;
	}
	td:nth-child(11), th:nth-child(11){  /* observatii */
	  /* width: calc(100% / 18 * 3); */
	  flex-grow:10;
	}
	td:nth-child(12), th:nth-child(12){  /* stadiu */
	  // max-width: 7em;
	  flex-grow:10;
	}
	td:nth-child(13), th:nth-child(13){  /* respins */
	  // max-width: 4em;
	  flex-grow:2;
	}
	td:nth-child(14), th:nth-child(14){  /* editor */
	  // max-width: 5em;
	  flex-grow:10;
	}
	td:nth-child(15), th:nth-child(15){  /* tip */
	  // max-width: 5em;
	  flex-grow:3;
	}
	td:nth-child(16), th:nth-child(16){  /* created */
	  /* max-width: 3%; */
	  flex-grow:30;
	}
	td:nth-child(17), th:nth-child(17){  /* poze */
	  width: 50px;
	  flex-grow:0;
	}
	td:nth-child(18), th:nth-child(18){  /* actiuni */
	  width: 150px;
	  /* flex-grow:5; */
	}

	span{
	  overflow: hidden;
	  white-space:nowrap;
	  word-break: break-word;
	  text-overflow: ellipsis;
	}

	// @media all and (max-width: 1600px) {
	//  td, th{
	//     width: calc((100% - 150px) / 4.2);
	//   }
	// }

	// @media all and (max-width: 1440px) {
	//   td, th{
	//     width: calc( (100% - 150px) / 4.3);
	//   }
	// }


	// @media all and (max-width: 1024px) {
	//   td, th{
	//     width: calc((100% - 150px) / 4);
	//   }
	// }

}

